import React from 'react'
import cubes from './images/cubos_no_fondo.png'
import './css/generalstyle.css'
import './css/main-style.css'

export default function Main() {
  return (
    <div>
        <h1 id="main_title">HexaLabs</h1>
        <h1 id="by_stevenmj">By StevenMJ</h1>
        <h1 id="subtitle">Front end developer, desktop app developer, software engineer</h1>
        <img src={cubes} id="cubes_images"/>
    </div>
  )
}