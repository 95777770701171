import React from 'react'
import './css/generalstyle.css'
import './css/project-style.css'
import './css/projectsstyle.css'
import imgprj1 from './images/imgprj1.PNG'
//import display from './scriptsjs/projects.js'
//import ScriptTag from 'react-script-tag';

export default function Projects() {
    var displayboolean = false;

function display(input) {
    if (displayboolean == false) {
        document.getElementsByClassName('projectmoreinfo')[input].style.padding = '20px';
        document.getElementsByClassName('projectmoreinfo')[input].style.height = 'fit-content';
        document.getElementsByClassName('displaybutton')[input].innerText  = 'View less';
    
    } else {
        document.getElementsByClassName('projectmoreinfo')[input].style.padding = '0px';
        document.getElementsByClassName('projectmoreinfo')[input].style.height = '0px';
        document.getElementsByClassName('displaybutton')[input].innerText  = 'View more';
    }
    displayboolean = !displayboolean;
}

  return (
    <div id="projects_container">
      <h1 id="projects_title">My Projects</h1>
      <div id="projects_list_container">

      <div class="projectcontainer" id="project1">
        <h2 class="projecttile" id="project1title">HexaLabs Portfolio</h2>
        <div class="projectbasicinfo" id="project1basicinfo">
            <p class="projectgeneralinfo" id="project1generalinfo">+ Type: Website<br />+ Framework: React<br />+ Languages: JSX, JavaScript, CSS and HTML<br />+ Description: This is the website you are currently on</p>
            <button class="medium-button displaybutton" id="project1viewbutton" onClick={()=>display(0)}>View more</button>
        </div>
        <div class="projectmoreinfo" id="project1moreinfo">
            <p id="a">The website was made with the React framework, it was made to serve as my personal portfolio to show my projects and my abilities</p>
            <div class="video_wrapper">
            <img src={imgprj1} class="imgprj" id="imgprj1"/>
            </div>
            <a class="projecthref" href="https://hexalabspage.pages.dev" target="_blank"><button class="medium-button gotoprojectspage">Go to projects page</button></a>
        </div>
    </div>
      </div>
    </div>
  )
}
//<iframe allowfullscreen class="videoiframe" src="https://www.youtube.com/embed/qSqDx1YCEtg?autoplay=0&mute=0"></iframe>