import React from 'react'
import Main from './Main';
import Projects from './Projects';

function App() {
  return (
    <>
     <Main />
     <Projects />
    </>
  )
}

export default App;
